<template>
  <div class="member-shop-order-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="連結名稱" prop="name" align="center" />
      <el-table-column label="開始時間" prop="startAt" align="center" />
      <el-table-column label="結束時間" prop="endAt" align="center" />
      <el-table-column label="連結狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="linkStatus(scope.row.status, 'tagType')">{{ linkStatus(scope.row.status, 'label') }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="給點次數" prop="times" align="center" width="140" />
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            editBtn="詳情"
            @edit="onRowView(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deletePointLink"
    />
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '@/utils/date'
import { get, map } from 'lodash'
import { pointLinkStatusConfig } from '@/config/point'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'

export default defineComponent({
  name: 'MemberShopPointLinkTable',
  components: {
    Tag,
    EmptyBlock,
    DeleteDialog,
  },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })
    const displayData = computed(() => {
      return map(props.tableData, i => {
        return {
          ...i,
          startAt: formatDate(i.startAt, 'YYYY-MM-DD HH:mm'),
          endAt: i.endAt ? formatDate(i.endAt, 'YYYY-MM-DD HH:mm') : '無期限',
          times: `${i.usedLinks}/${i.totalLinks}`,
        }
      })
    })
    const linkStatus = (status, attr) => {
      return get(pointLinkStatusConfig[status], attr)
    }
    const onRowView = (row) => {
      router.push({ name: 'MemberShopPointLinkDetail', params: { id: row.id } })
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }
    const deletePointLink = () => {
      emit('delete', get(selectRow.value, 'id'))
      modal.delete = false
    }
    return {
      displayData,
      onRowView,
      onRowDelete,
      modal,
      deletePointLink,
      linkStatus,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
