<template>
  <main class="member-shop-order-manage">
    <PageTitle title="點數連結管理" btn="新增" @btnClick="$router.push({name: 'MemberShopPointLinkEdit'})" />
    <MemberShopPointLinkTable v-loading="loading.table" :tableData="tableData" @delete="deletePointLink" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </main>
</template>

<script>
import { defineComponent, onMounted, onActivated } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberShopPointLinkTable from './components/MemberShopPointLinkTable.vue'
import { useTable } from '@/use/table'
import { GetShopPointLink, GetShopPointLinkCount, DeleteShopPointLink } from '@/api/shopPoint'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPointLink',
  components: {
    MemberShopPointLinkTable,
    PageTitle,
  },
  setup (props) {
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex, fetchData, fetchDataCount } = useTable()
    const { shopId } = useShop()

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetShopPointLink, payload),
        fetchDataCount(GetShopPointLinkCount, payload),
      ])
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await Promise.all([
        getTableData(),
      ])
      loading.table = false
    }
    const deletePointLink = async (id) => {
      const [, err] = await DeleteShopPointLink({ shopId: shopId.value, id })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      await refresh()
    }
    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })

    return {
      tableData,
      tableOptions,
      loading,
      refresh,
      tableDataCount,
      deletePointLink,
    }
  },
})
</script>

<style scoped lang="postcss">
</style>
